import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import Button from "react-bootstrap/Button";



import { BsGithub } from "react-icons/bs";
function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Jakub</span>
            <b/> from<span className="purple"> Poland.</span>
            <br /> For several years I have been passionately devoted to
            programming, which resulted in the creation of a
            variety of websites and applications that I have successfully made
            available on <span className="purple"> Google Play</span>
            <br />
            From an early age, I was fascinated by computers and technology. Even as a child, I loved to take apart various devices, trying to understand how they worked, and then putting them back together with satisfaction. This passion for technology has developed over the years and has translated into my professional path, where knowledge of hardware and software have become my strong suits.

            <br />
            <br />

            
            <Button
            href="https://github.com/JakubJakubiak"
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <BsGithub /> &nbsp; {"GitHub"}
          </Button>
        
          </p>

          {/* <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">JakubJakubiak</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
